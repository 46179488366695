<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }"
              >
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                v-if="$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
                href="#"
              >{{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}</a>
              <a
                v-else
                href
              >Minhas capacitacoes</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left">
                            <h1
                              v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
                              class="aluno_font_color"
                            >
                              Minhas
                              <span>capacitações</span>
                            </h1>
                            <h1
                              v-else
                              class="aluno_font_color"
                            >
                              {{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}
                            </h1>
                            <p
                              v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
                              class="aluno_font_color"
                            >
                              Veja as suas capacitações disponíveis
                            </p>
                            <p
                              v-else
                              class="aluno_font_color"
                            >
                              {{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }} disponíveis
                            </p>
                            <div class="fd-welcome-form-effect">
                              <img
                                :src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                          <section class="fd-app-meus-cursos">
                            <div class="fd-app-meus-cursos-list">
                              <div
                                id="divListaMatriz"
                                class="row justify-content-around"
                              >
                                <!-- fd-app-capacitacoes -->
                                <section class="fd-app-capacitacoes col-12 aluno_font_color">
                                  <div
                                    v-if="fastMinhasCapacitacoes.length"
                                    id="divListaMinhasCapacitacoes"
                                    class="fd-app-capacitacoes-list"
                                  >
                                    <div
                                      v-for="cargo in fastMinhasCapacitacoes"
                                      :key="cargo.id_cargo"
                                      class="fd-app-capacitacoes-list-item d-flex"
                                    >
                                      <h3>
                                        <a class="aluno_font_color">{{ cargo.nome_cargo }}</a>
                                      </h3>
                                      <div class="ml-auto d-flex">
                                        <div class="fd-app-capacitacoes-vercursos pc">
                                          <a
                                            href="#"
                                            @click.prevent="exibeModalCursos(cargo)"
                                          >Ver Cursos</a>
                                        </div>
                                        <div class="fd-app-capacitacao-progress">
                                          <div
                                            id="barraProgressoCargoStatus_1031"
                                            class="fd-app-capacitacao-progress-fill"
                                            :style="'width: ' + cargo.porcentagem_conclusao + '%'"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                                <!-- fd-app-capacitacoes -->
                              </div>
                            </div>
                          </section>

                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modals -->
    <modal
      name="modalCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div
        v-if="fastCargoSelecionado.id_cargo"
        class="row p-4"
      >
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4
            v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
          >
            Cursos do cargo {{ fastCargoSelecionado.nome_cargo }}
          </h4>
          <h4
            v-else
          >
            {{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}: {{ fastCargoSelecionado.nome_cargo }}
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 mt-4 pl-4 pr-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      Nome do curso
                    </th>
                    <th scope="col">
                      Carga horária
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastCargoSelecionado.cursos.length">
                  <tr
                    v-for="(curso, index) in fastCargoSelecionado.cursos"
                    :key="curso.id_curso"
                  >
                    <td scope="row">
                      {{ index + 1 }}
                    </td>
                    <td class="align-middle">
                      {{ curso.nome_curso }}
                    </td>
                    <td class="align-middle">
                      {{ curso.carga_horaria }}
                    </td>
                    <td class="align-middle">
                      {{ curso.status }}
                    </td>
                    <td class="align-middle text-center">
                      <a
                        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + curso.id_curso"
                        class="btn btn-sm btn-success btn-block"
                        title="Acessar o curso"
                      >
                        <small>Acessar</small>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhum curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAluno",
  components: {
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      nomeUsuario: "",
      imageUsuario: this.retornaSessao(settings.fastSessaoUsuario).Image,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Capacitacoes
      fastMinhasCapacitacoes: [],
      fastCargoSelecionado: [],
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.getMinhasCapacitacoes();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getMinhasCapacitacoes() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_matriz/lista_detalhes?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          const capacitacoes = Array.from(
            new Set(obj.map((a) => a.id_cargo))
          ).map((id_cargo) => {
            return obj.find((a) => a.id_cargo === id_cargo);
          });

          capacitacoes.forEach((cargo, index) => {
            cargo.porcentagem_conclusao = 0;

            obj.forEach((curso, index2) => {
              if (cargo.id_cargo == curso.id_cargo) {
                if (!capacitacoes[index].cursos)
                  capacitacoes[index].cursos = [];
                curso.status = "";
                capacitacoes[index].cursos.push(curso);
              }
            });
          });
          console.log("capacitacoes", capacitacoes);
          this.fastMinhasCapacitacoes = capacitacoes;

          this.fastMinhasCapacitacoes.forEach((cargo) => {
            this.getPorcentagemConclusao(cargo.id_cargo);
            if (cargo.cursos) {
              cargo.cursos.forEach((curso) => {
                this.getStatusCurso(curso.id_curso);
              });
            }
          });
        }
        //console.log("getMinhasCapacitacoes", obj);

        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalCursos(cargo) {
      this.fastCargoSelecionado = cargo;
      this.showModal("modalCursos");
    },
    async getPorcentagemConclusao(id_cargo) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_matriz/status_porcentagem?id_cargo=" +
            id_cargo,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastMinhasCapacitacoes.forEach((c) => {
            if (c.id_cargo == id_cargo)
              c.porcentagem_conclusao = obj[0].porcentagem_conclusao;
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getStatusCurso(id_curso) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso/lista?id_curso=" +
            id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          if (obj[0].count) {
            this.fastMinhasCapacitacoes.forEach((cargo) => {
              this.getPorcentagemConclusao(cargo.id_cargo);
              if (cargo.cursos) {
                cargo.cursos.forEach((curso) => {
                  if (curso.id_curso == id_curso) curso.status = "Realizado";
                  else curso.status = "Em andamento";
                });
              }
            });
          } else {
            this.fastMinhasCapacitacoes.forEach((cargo) => {
              this.getPorcentagemConclusao(cargo.id_cargo);
              if (cargo.cursos) {
                cargo.cursos.forEach((curso) => {
                  if (curso.id_curso == id_curso)
                    curso.status = "Não realizado";
                });
              }
            });
          }
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style></style>
